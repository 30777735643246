import { useEffect, RefObject } from "react";
import { appModel } from "../../models/AppModel";

function useClickOutside(ref: RefObject<HTMLElement>, onClickOutside: (e) => void) {
  useEffect(() => {
    function handleOutsideClick(event: PointerEvent) {
      if (ref.current && !ref.current.contains(event.target as Node) && appModel.selectedRoomWall) {
        onClickOutside(event);
      }
    }

    document.addEventListener("pointerdown", handleOutsideClick, { capture: true });

    return () => {
      document.removeEventListener("pointerdown", handleOutsideClick, { capture: true });
    };
  }, [ref, onClickOutside]);
}

export default useClickOutside;

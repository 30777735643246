import { IReactionDisposer, reaction } from "mobx";
import * as THREE from "three";

import { appModel } from "../../../models/AppModel";
import { CursorStyle } from "../../../models/CursorStyle";
import { CorePlan } from "../../../models/CorePlan";
import { DragMode } from "../../models/DragMode";
import { Keys } from "../../models/Keys";
import { SceneEditorMode } from "../../models/SceneEditorMode";
import { WallAnalysisUtils } from "../../utils/WallAnalysisUtils";
import { SceneEntityType } from "../../models/SceneEntityType";

import RoomManager from "./RoomManager";
import { HOVERD_WALL, SELECTED_WALL, SELECTED_WALL_RENDER_ORDER, WALL_RENDER_ORDER } from "../../consts";
import { settings } from "../../../entities/settings";
import { WebAppUISettingsKeys } from "../../../entities/settings/types";

export default class RoomWallManager {
  private reactions: IReactionDisposer[] = [];
  private dragMode: DragMode = DragMode.none;

  constructor(public roomManager: RoomManager) {
    reaction(() => appModel.activeCorePlan, this.onActiveCorePlanChanged.bind(this), { fireImmediately: true });
    reaction(() => appModel.sceneEditorMode, this.onEditModeChanged.bind(this));
  }

  public onMouseMove(e: MouseEvent) {
    const intersectedWall = this.getIntersectedWall() as any;
    const intersectedOpening = this.roomManager.roomOpeningManager.getIntersectedOpening();
    this.resetWallSelection();

    if (intersectedWall && !intersectedOpening) {
      (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Pointer;
      this.handleHoverWall(intersectedWall);
    } else {
      (e.target as HTMLCanvasElement).style.cursor = CursorStyle.Default;
    }
    if (appModel.selectedRoomWall) {
      const selectedWall = appModel.selectedRoomWall;

      if (selectedWall) {
        this.addSoWallToSelected(selectedWall);
        appModel.setTooltipOptions({ show: false });
      }
    }
  }

  private resetWallSelection(): void {
    const activeFloor = this.getActiveFloorSynteticSoWalls();
    activeFloor.forEach(wall => {
      const copyWall = wall.clone() as any;
      copyWall.material.color.set(settings.getColorNumber(WebAppUISettingsKeys.wallsColor));
      copyWall.renderOrder = WALL_RENDER_ORDER;
    });
  }

  public onMouseLeave(e: MouseEvent): void {
    appModel.setSceneEditorMode(SceneEditorMode.Room);
  }

  public onMouseUp(e: MouseEvent) {
    appModel.clearSelectedRoomsIds();
    const intersectedOpening = this.roomManager.roomOpeningManager.getIntersectedOpening();

    if (appModel.isViewOnlyMode || !this.roomManager.baseManager.isMouseHandlersEnabled) {
      return;
    }

    if (this.dragMode === DragMode.none) {
      if (e.button === 0) {
        const intersectedWall = this.getIntersectedWall() as THREE.Object3D;
        const intersectedWallStart = intersectedWall?.userData.segment?.start;
        const selectedWallStart = appModel.selectedRoomWall?.userData.segment?.start;
        if (
          appModel.selectedRoomWall &&
          intersectedWallStart &&
          selectedWallStart &&
          intersectedWallStart.x === selectedWallStart.x &&
          intersectedWallStart.y === selectedWallStart.y
        ) {
          this.resetWallSelection();
          appModel.clearSelectedRoomWalls();
          appModel.setSceneEditorMode(SceneEditorMode.Room);

          return;
        }
        appModel.clearSelectedRoomOpenings();
        this.resetWallSelection();
        if (intersectedWall && !intersectedOpening) {
          this.addSoWallToSelected(intersectedWall);
        } else {
          appModel.setSceneEditorMode(SceneEditorMode.Room);
          this.clearSelection();
        }
      }
      return;
    }
  }

  private regenerateRoomsWalls(wall = null) {
    const activeFloor = this.roomManager.getActiveSoFloor();
    if (activeFloor) {
      WallAnalysisUtils.regenerateRoomsWalls(activeFloor);
    }
  }

  public clearSelection() {
    this.addSoWallToSelected(null);
    appModel.addSelectedRoomWall(null);
  }

  public init() {
    this.clearSelection();
    this.resetWallSelection();
  }

  public getIntersectedWall(): THREE.Object3D | null {
    const activeFloor = this.getActiveFloorSynteticSoWalls();

    const intersections = this.roomManager.raycaster.intersectObjects(activeFloor);

    return intersections.length ? intersections[0].object : null;
  }

  public onKeyUp(e: KeyboardEvent) {
    if (appModel.isViewOnlyMode) {
      return;
    }

    switch (e.code) {
      case Keys.Esc: {
        this.clearSelection();
        this.resetWallSelection();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
        break;
      }
      case Keys.Y: {
        this.redo();
        break;
      }
      case Keys.Z: {
        this.undo();
        break;
      }
    }
  }
  //Need to add this.roomManager.commandManager.add for spesific command
  public undo(): void {
    if (this.dragMode === DragMode.none) {
      const command = this.roomManager.commandManager.undo();

      if (command) {
        appModel.clearSelectedRoomWalls();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
      }
    }
  }

  //Need to add this.roomManager.commandManager.add for spesific command
  public redo(): void {
    if (this.dragMode === DragMode.none) {
      const command = this.roomManager.commandManager.redo();
      if (command) {
        appModel.clearSelectedRoomWalls();
        appModel.setSceneEditorMode(SceneEditorMode.Room);
      }
    }
  }

  public addSoWallToSelected(soWall: THREE.Object3D): void {
    if (!soWall) return;
    const syntheticWalls = this.getActiveFloorSynteticSoWalls();

    syntheticWalls.forEach(sWall => {
      const soWallStart = soWall.userData.segment?.start;
      const sWallStart = sWall.userData.segment?.start;
      if (soWallStart && sWallStart && soWallStart.x === sWallStart.x && soWallStart.y === sWallStart.y) {
        const wallMesh = sWall as THREE.Mesh;
        if (wallMesh.material instanceof THREE.MeshStandardMaterial || wallMesh.material instanceof THREE.MeshBasicMaterial) {
          wallMesh.material.color.set(SELECTED_WALL);
          wallMesh.renderOrder = SELECTED_WALL_RENDER_ORDER;
        }
      }
    });
    appModel.addSelectedRoomWall(soWall);
  }

  public handleHoverWall(soWall: THREE.Object3D): void {
    if (!soWall) return;
    const syntheticWalls = this.getActiveFloorSynteticSoWalls();

    syntheticWalls.forEach(sWall => {
      const soWallStart = soWall.userData.segment?.start;
      const sWallStart = sWall.userData.segment?.start;
      if (soWallStart && sWallStart && soWallStart.x === sWallStart.x && soWallStart.y === sWallStart.y) {
        const wallMesh = sWall as THREE.Mesh;
        if (wallMesh.material instanceof THREE.MeshStandardMaterial || wallMesh.material instanceof THREE.MeshBasicMaterial) {
          wallMesh.material.color.set(HOVERD_WALL);
          wallMesh.renderOrder = SELECTED_WALL_RENDER_ORDER;
        }
      }
    });
    appModel.setTooltipOptions({ show: false });
  }

  public leaveWall(): void {
    this.regenerateRoomsWalls();
  }

  private onActiveCorePlanChanged(corePlan?: CorePlan): void {
    this.unsubscribe(this.reactions);

    if (corePlan) {
      this.subscribeActiveCorePlan();
    }
  }

  private onActiveFloorChanged(): void {
    appModel.clearSelectedRoomOpenings();
  }

  private onEditModeChanged(mode: SceneEditorMode, oldMode: SceneEditorMode): void {
    if (oldMode === SceneEditorMode.RoomOpening) {
      appModel.clearSelectedRoomOpenings();
    }
  }

  private subscribeActiveCorePlan(): void {
    this.reactions.push(reaction(() => appModel.activeFloor, this.onActiveFloorChanged.bind(this)));
  }

  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private getActiveFloorSynteticSoWalls(): THREE.Object3D[] {
    return this.roomManager.getActiveSoFloor()?.children.flatMap(this.extractSynteticSoWalls) ?? [];
  }

  private extractSynteticSoWalls(soRoom: THREE.Object3D): THREE.Object3D[] {
    return soRoom.children.filter(child => {
      return child.userData.type === SceneEntityType.SyntheticWall;
    });
  }

  public getActiveFloorWall(wall: any): THREE.Object3D {
    return this.getActiveFloorSynteticSoWalls().find(soWall => {
      const soWallbb = new THREE.Box3().setFromObject(soWall);
      const wallbb = new THREE.Box3().setFromObject(wall);
      soWallbb.intersectsBox(wallbb);
    });
  }
}

import { catalogSettings } from "../../entities/catalogSettings";
import { WallType } from "../../entities/catalogSettings/types";
import { settings } from "../../entities/settings";
import { appModel } from "../../models/AppModel";
import { SceneDisplayUnits } from "../../models/SceneDisplayUnits";

export default class UnitsUtils {
  public static readonly INCHES2FEET = 1.0 / 12.0;

  static metersToFeet(value: number): number {
    return value * 3.280839895;
  }
  static feetToMeters(value: number): number {
    return value / 3.280839895;
  }
  static inchesToFeet(value: number): number {
    return value / 12.0;
  }
  static feetToInches(value: number): number {
    return value * 12.0;
  }

  static getConversionFactor(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Inch:
        return 5.0;
      case SceneDisplayUnits.Foot:
      default:
        return 1.0;
    }
  }

  static getConversionFactorFromInches(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Inch:
        return 1.0;
      case SceneDisplayUnits.Foot:
      default:
        return 1 / 12;
    }
  }

  static getSelectionPrecision(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.3;
      case SceneDisplayUnits.Inch:
      default:
        return 0.3; // ?
    }
  }
  static getSnapTolerance(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 1.5;
      case SceneDisplayUnits.Inch:
      default:
        return 1.5; // ?
    }
  }

  /**
   * Returns base App's round precision, used mostly to round objects size
   */
  static getRoundPrecision(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 100;
      case SceneDisplayUnits.Inch:
      default:
        return 100; // ?
    }
  }

  static getRoundFractionPrecision(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
      case SceneDisplayUnits.Inch:
        return 8;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }

  static getGridCellSize(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return Math.max(UnitsUtils.inchesToFeet(appModel.gridUnitSizeInches), 1);
      case SceneDisplayUnits.Inch:
      default:
        return Math.max(appModel.gridUnitSizeInches, 1);
    }
  }
  static getBasePointSize(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.35 * 12;
      case SceneDisplayUnits.Inch:
      default:
        return 0.35 * 12;
    }
  }
  static getIntersectionsPlaneSize(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 100000;
      case SceneDisplayUnits.Inch:
      default:
        return 1000000;
    }
  }

  static getRulerThickness(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 2.3;
      case SceneDisplayUnits.Inch:
      default:
        return 2.3 * 1.5;
    }
  }
  static getRulerFontSize(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.28;
      case SceneDisplayUnits.Inch:
      default:
        return 0.28 * 8;
    }
  }

  static getDimensionLabelFontSize(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.7;
      case SceneDisplayUnits.Inch:
      default:
        return 0.7 * 12;
    }
  }
  static getRoomDimensionLinesOffset(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 2.0;
      case SceneDisplayUnits.Inch:
      default:
        return 2.0 * 12;
    }
  }
  static getFloorDimensionLinesOffset(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 6.0;
      case SceneDisplayUnits.Inch:
      default:
        return 6.0 * 12;
    }
  }
  static getMaxWallDeviation(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.05;
      case SceneDisplayUnits.Inch:
      default:
        return 0.05 * 12;
    }
  }
  static getSyntheticWallHalfSize(): number {
    try {
      return (catalogSettings.walls[WallType.EXT].exteriorThickness + catalogSettings.walls[WallType.EXT].interiorThickness) / 2;
    } catch (error) {
      console.error("Error calculating synthetic wall half size:", error);
      return 0;
    }
  }
  static getFloorBackgroundInitialSize(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 20.0;
      case SceneDisplayUnits.Inch:
      default:
        return 20.0 * 12;
    }
  }
  static getAreaCalculationExteriorOffset(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return settings.values.validationSettings.areaCalculationExteriorOffset / 12;
      case SceneDisplayUnits.Inch:
      default:
        return settings.values.validationSettings.areaCalculationExteriorOffset;
    }
  }
  static getMaxFloorSpan(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return settings.values.validationSettings.gravityMaxFloorSpan / 12;
      case SceneDisplayUnits.Inch:
      default:
        return settings.values.validationSettings.gravityMaxFloorSpan;
    }
  }
  static getMinFloorSpan(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 3.0;
      case SceneDisplayUnits.Inch:
      default:
        return 3.0 * 12;
    }
  }
  static getMaxCantileverSpan(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return settings.values.validationSettings.gravityMaxCantileverSpan / 12;
      case SceneDisplayUnits.Inch:
      default:
        return settings.values.validationSettings.gravityMaxCantileverSpan;
    }
  }
  static getMaxBeamLength(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return settings.values.validationSettings.gravityMaxBeamSpan / 12;
      case SceneDisplayUnits.Inch:
        return settings.values.validationSettings.gravityMaxBeamSpan;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }

  static getGridMinorLineDashSize(): number {
    // psf, pound per square foot.
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.05;
      case SceneDisplayUnits.Inch:
      default:
        return 0.05 * 12;
    }
  }

  static getRoofDepth(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.3385416667;
      case SceneDisplayUnits.Inch:
        return 4.0625;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }

  static getSweepDepth(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.8333;
      case SceneDisplayUnits.Inch:
        return 10.0;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }
  static getSweepOffset(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 1.5;
      case SceneDisplayUnits.Inch:
        return 18.0;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }
  // static getOpeningAlignmentMinGap(): number {
  //   switch (appModel.sceneDisplayUnit) {
  //     case SceneDisplayUnits.Foot: return settings.values.validationSettings.openingAlignmentMinGap / 12;
  //     case SceneDisplayUnits.Inch: return settings.values.validationSettings.openingAlignmentMinGap;
  //     default: throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
  //   }
  // }
  static getStretchingAllowance(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return settings.values.validationSettings.stretchAllowance / 12;
      case SceneDisplayUnits.Inch:
        return settings.values.validationSettings.stretchAllowance;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }

  static getRoomOpeningSelectedBorderSize(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 0.125;
      case SceneDisplayUnits.Inch:
        return 1.5;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }

  static getRoomOpeningSelectedRangeHeight(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 1.16666666667;
      case SceneDisplayUnits.Inch:
        return 14;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }

  static getCladdingLineThickness(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Foot:
        return 1 / 12;
      case SceneDisplayUnits.Inch:
        return 1;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }

  static getMinimalWallSegmentLength(): number {
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Inch:
        return settings.values.validationSettings.minimalWallSegmentLength;
      case SceneDisplayUnits.Foot:
        return settings.values.validationSettings.minimalWallSegmentLength / 12;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }

  static getShortWallSegmentIndicatorMaxLength(): number {
    const value = 30;
    switch (appModel.sceneDisplayUnit) {
      case SceneDisplayUnits.Inch:
        return value;
      case SceneDisplayUnits.Foot:
        return value / 12;
      default:
        throw new Error(`Unsupported: ${appModel.sceneDisplayUnit}`);
    }
  }
}

import { LayerType, RevitWall, Wall, WallType } from "./types";

export const parseRevitWalls = (revitWalls: RevitWall[]): Record<WallType, Wall> => {
  const result = {} as Record<WallType, Wall>;

  revitWalls.forEach(revitWall => {
    const layersThicknesses = {
      [LayerType.EXTERIOR]: { current: 0, initial: 0 },
      [LayerType.CORE]: { current: 0, initial: 0 },
      [LayerType.INTERIOR]: { current: 0, initial: 0 },
    };

    revitWall.layers.forEach(layer => {
      layersThicknesses[layer.type].current += layer.thickness;
      layersThicknesses[layer.type].initial = layer.thickness;
    });

    const halfCoreWidth = layersThicknesses[LayerType.CORE].current / 2;

    result[revitWall.type] = {
      interiorThickness: layersThicknesses[LayerType.INTERIOR].current + halfCoreWidth,
      exteriorThickness: layersThicknesses[LayerType.EXTERIOR].current + halfCoreWidth,
      coreThickness: halfCoreWidth,
      initialInteriorThickness: layersThicknesses[LayerType.INTERIOR].initial,
      initialExteriorThickness: layersThicknesses[LayerType.EXTERIOR].initial,
      initialCoreThickness: layersThicknesses[LayerType.CORE].initial,
    };
  });

  return result;
};

export enum WallType {
  EXT = "EXT",
  INT = "INT",
  GRG = "GRG",
  DDL = "DDL",
  PLM = "PLM",
  SFT = "SFT", // Shaft
  KNE = "KNE", // Knee
  SHW = "SHW", // Bath/Shower
}

export enum WallLabel {
  EXT = "Extrior",
  INT = "Interior",
  SHW = "Wet",
  SFT = "Shaft",
  GRG = "Garage",
  KNE = "Knee",
}

export enum LayerType {
  EXTERIOR = "EXTERIOR",
  INTERIOR = "INTERIOR",
  CORE = "CORE",
}

export interface Layer {
  thickness: number;
  type: LayerType;
}

export interface RevitWall {
  layers: Layer[];
  type: WallType;
}

export interface Wall {
  interiorThickness: number;
  exteriorThickness: number;
  coreThickness: number;
  initialInteriorThickness?: number;
  initialExteriorThickness?: number;
  initialCoreThickness?: number;
}
